import React from "react"
import Layout from '../components/Layout'
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const FAQ = () => {
    return (
        <Layout>
        <SEO title={"FAQ"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            FAQ
                        </h1>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

        </Layout>
    )
}

export default FAQ